import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper("#js-homeSwiper", {
  autoplay: {
    delay: 3500,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
      slidesPerView: 1.4,
      spaceBetween: 20,
    },
  },
  centeredSlides: true,
  loop: true,
  spaceBetween: 0,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  slidesPerView: 1,
  speed: 450,
});
