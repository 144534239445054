import $ from "jquery";

const igBusinessAccountId = '17841433809912189';
const showLimit = 8;
const accessToken = 'EAADK9QxOPPgBAGWVqTqlTKwc1QY0c3SUp7YfPbQYDB344MKUX3SYz1v065wY8hklGX7MQxV7UIyGLq2X1x5Ar6SGmucUNA1z5BdNQWMdE3CMCWC88uf6uqc7lPIxWO3edwZCqZC4W9GMWEABShtyEbQRQZApKe4xJvweVMyOmRY0PMhgZCKJ';
$.ajax({
  type: 'GET',
  url: 'https://graph.facebook.com/v6.0/' + igBusinessAccountId + '?fields=name%2Cmedia.limit(' + showLimit + ')%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=' + accessToken,
  dataType: 'json',
  success: function(json) {
    let html = '';
    const insta = json.media.data;
    for (var i = 0; i < insta.length; i++) {
      html += '<div><a href="' + insta[i].permalink + '" target="_blank"><img src="' + insta[i].media_url + '" alt=""></a></div>';
    }
    $("#js-instagram").append(html);
  },
  error: function() {
  //エラー時の処理
  }
});
